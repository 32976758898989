/* body * {
  border: 1px solid red;
} */

@font-face {
  font-family: "OSBI";
  src: URL("./fonts/OpenSauceOne-BlackItalic.ttf") format("truetype");
}

@font-face {
  font-family: "OS";
  src: URL("./fonts/OpenSauceOne-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "OSI";
  src: URL("./fonts/OpenSauceOne-Italic.ttf") format("truetype");
}

body * {
  font-family: "OS";
  font-size: 15px;
}

.OSBI {
  font-family: "OSBI";
}

.OS {
  font-family: "OS";
}

.OSI {
  font-family: "OSI";
}

.gradienttext {
  background: rgb(234, 5, 240);
  background: linear-gradient(
    112deg,
    rgba(234, 5, 240, 1) 0%,
    rgba(9, 9, 121, 1) 60%,
    rgba(0, 212, 255, 1) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav ul li {
  list-style-type: none;
}

.nav {
  align-items: center;
}

.br {
  border-radius: 0px;
}

.hero--image {
  background-image: url("./media/ukraine.png");
  background-size: cover;
  background-position: center;
}

.hero--logo {
  background-image: url("./media/me.png");
  width: 400px;
  height: 400px;
  background-size: contain;
  background-position: center;
  align-self: center;
  margin: auto;
}

.hero--textbox {
  margin: auto;
  max-width: 250px;
}

.hero--padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

.link a {
  font-family: sans-serif;
  text-decoration: none;
  color: gray;
  position: relative;
  margin: 10px 0;
  display: inline-block;
  padding: 3px 0px;
}

/* .link a::after {
  content: "";
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  height: 2px;
  position: absolute;
  bottom: 0;
  transition: 0.16s all 0.025s;
}

.link a::after {
  left: 50%;
  right: 50%;
} */

.link a:hover ~ a::after {
  left: 0;
  right: 100%;
}

.link a:hover {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.link a:hover::after {
  left: 0;
  right: 0;
}

.logo a {
  font-size: 20px;
  cursor: pointer;
  color: inherit;
  text-decoration: inherit;
  color: black;
}

.logo a:hover {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.up {
  margin-top: -30px;
}

.top--bg {
  background: rgb(255, 255, 255);
}

.bg {
  background-image: url("media/diamond2.png");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.footer {
  margin-top: auto;
  background-image: url("./media/fadesmall.png");
  background-size: cover;
  transform: rotate(180deg);
}

.shop--video {
  background-image: url(./media/ShopHeader.webm);
}

.shop--card {
  height: 100px;
  margin-top: -65px;
  position: relative;
  z-index: 100000;
  background-color: white;
}

.scroll {
  overflow: hidden;
}

.video {
  height: 400px;
}

.box {
  width: 100px;
  height: 100px;
  background-color: red;
}

.btn-grad {
  background-image: linear-gradient(
    to right,
    #ff512f 0%,
    #dd2476 51%,
    #ff512f 100%
  );
}
.btn-grad {
  margin: 10px;
  padding: 10px 15px;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 20px;
}

.btn-grad:hover {
  background-position: right center;
  text-decoration: none;
}

button {
  border: none;
}

/* SWITCH */

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  padding-bottom: 21px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  -webkit-transition: 0.33s ease-in-outs;
  transition: 0.33s ease-in-out;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: lightgrey;
  background-position: center;
  -webkit-transition: 0.33s ease-in-out;
  transition: 0.33s ease-in-out;
}

input:checked + .slider {
  background-color: black;
}

input:checked + .slider:before {
  content: "";
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  background-color: white;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.ml-0 {
  margin-left: 0px;
}
